import { colors } from "./colors";

export const heatmapData = [
  { id: "US-AK", label: "AK", name: "Alaska", rate: 0.225 },
  { id: "US-AL", label: "AL", name: "Alabama", rate: 0.312 },
  { id: "US-AR", label: "AR", name: "Arkansas", rate: 0.327 },
  { id: "US-AZ", label: "AZ", name: "Arizona", rate: 0.323 },
  { id: "US-CA", label: "CA", name: "California", rate: 0.374 },
  { id: "US-CO", label: "CO", name: "Colorado", rate: 0.303 },
  { id: "US-CT", label: "CT", name: "Connecticut", rate: 0.419 },
  { id: "US-DC", label: "DC", name: "District of Columbia", rate: 0.597 },
  { id: "US-DE", label: "DE", name: "Delaware", rate: 0.191 },
  { id: "US-FL", label: "FL", name: "Florida", rate: 0.371 },
  { id: "US-GA", label: "GA", name: "Georgia", rate: 0.352 },
  { id: "US-HI", label: "HI", name: "Hawaii", rate: 0.346 },
  { id: "US-IA", label: "IA", name: "Iowa", rate: 0.462 },
  { id: "US-ID", label: "ID", name: "Idaho", rate: 0.224 },
  { id: "US-IL", label: "IL", name: "Illinois", rate: 0.393 },
  { id: "US-IN", label: "IN", name: "Indiana", rate: 0.246 },
  { id: "US-KS", label: "KS", name: "Kansas", rate: 0.317 },
  { id: "US-KY", label: "KY", name: "Kentucky", rate: 0.233 },
  { id: "US-LA", label: "LA", name: "Louisiana", rate: 0.298 },
  { id: "US-MA", label: "MA", name: "Massachusetts", rate: 0.523 },
  { id: "US-MD", label: "MD", name: "Maryland", rate: 0.252 },
  { id: "US-ME", label: "ME", name: "Maine", rate: 0.285 },
  { id: "US-MI", label: "MI", name: "Michigan", rate: 0.241 },
  { id: "US-MN", label: "MN", name: "Minnesota", rate: 0.303 },
  { id: "US-MO", label: "MO", name: "Missouri", rate: 0.28 },
  { id: "US-MS", label: "MS", name: "Mississippi", rate: 0.383 },
  { id: "US-MT", label: "MT", name: "Montana", rate: 0.233 },
  { id: "US-NC", label: "NC", name: "North Carolina", rate: 0.307 },
  { id: "US-ND", label: "ND", name: "North Dakota", rate: 0.222 },
  { id: "US-NE", label: "NE", name: "Nebraska", rate: 0.583 },
  { id: "US-NH", label: "NH", name: "New Hampshire", rate: 0.343 },
  { id: "US-NJ", label: "NJ", name: "New Jersey", rate: 0.354 },
  { id: "US-NM", label: "NM", name: "New Mexico", rate: 0.299 },
  { id: "US-NV", label: "NV", name: "Nevada", rate: 0.499 },
  { id: "US-NY", label: "NY", name: "New York", rate: 0.634 },
  { id: "US-OH", label: "OH", name: "Ohio", rate: 0.257 },
  { id: "US-OK", label: "OK", name: "Oklahoma", rate: 0.252 },
  { id: "US-OR", label: "OR", name: "Oregon", rate: 0.307 },
  { id: "US-PA", label: "PA", name: "Pennsylvania", rate: 0.441 },
  { id: "US-PR", label: "PR", name: "Puerto Rico", rate: 0.085 },
  { id: "US-RI", label: "RI", name: "Rhode Island", rate: 0.471 },
  { id: "US-SC", label: "SC", name: "South Carolina", rate: 0.352 },
  { id: "US-SD", label: "SD", name: "South Dakota", rate: 0.353 },
  { id: "US-TN", label: "TN", name: "Tennessee", rate: 0.395 },
  { id: "US-TX", label: "TX", name: "Texas", rate: 0.329 },
  { id: "US-UT", label: "UT", name: "Utah", rate: 0.586 },
  { id: "US-VA", label: "VA", name: "Virginia", rate: 0.241 },
  { id: "US-VT", label: "VT", name: "Vermont", rate: 0.541 },
  { id: "US-WA", label: "WA", name: "Washington", rate: 0.344 },
  { id: "US-WI", label: "WI", name: "Wisconsin", rate: 0.258 },
  { id: "US-WV", label: "WV", name: "West Virginia", rate: 0.174 },
  { id: "US-WY", label: "WY", name: "Wyoming", rate: 0.206 },
];
export function formatPercent(n: number | undefined): string {
  if (!n) {
    return "";
  }
  const rounded = Math.round(n * 1000) / 10;
  let suffix = "";
  if (rounded === Math.round(rounded)) {
    suffix = ".0";
  }

  return rounded + suffix + "%";
}

export function calculateColor(value: number) {
  let color = null;
  if (value >= 0.05) {
    color = colors["red-4"];
  }
  if (value >= 0.25) {
    color = colors["red-3"];
  }
  if (value >= 0.3) {
    color = colors["red-2"];
  }
  if (value >= 0.36) {
    color = colors["red"];
  }
  return color;
}
export function valueByState(id: string) {
  const found = heatmapData.find((state) => state.id === id);
  if (found) {
    return found.rate;
  }
  return 0;
}
