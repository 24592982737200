import * as am5 from "@amcharts/amcharts5";

type ColorMap = {
  [key: string]: am5.Color;
};
export const colors: ColorMap = {
  "grey-1": am5.color("#F1F1F1"),
  "grey-2": am5.color("#E8E8E8"),
  "grey-3": am5.color("#DFDFDF"),
  "grey-4": am5.color("#D6D6D6"),
  "grey-5": am5.color("#CECECE"),
  red: am5.color("#b52a40"),
  "red-2": am5.color("#c86078"),
  "red-3": am5.color("#d68c9e"),
  "red-4": am5.color("#e6bac5"),
  white: am5.color("#ffffff"),
};
