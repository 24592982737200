import React, { FunctionComponent, useState, useEffect } from "react";
//import "intersection-observer";
import { useInView } from "react-intersection-observer";

import "./DonutGraph.scss";
import {
  generateRandomString,
  isIE,
  mergeClassNames,
  prefersReducedMotion,
} from "./helpers";
//import ImageWrapper from "../ImageWrapper";

const { cos, sin, PI } = Math;

const INTERVAL = 5;
const DT = 0.005;
//const EASE_OUT: (t: number) => number = t => t * (2 - t);
const EASE: (t: number) => number = (t) => t * t * (2 - t);

type Props = {
  alt: string;
  className?: string;
  percent: number;
  prefix?: string;
  radius?: number;
  startTheta?: number;
  strokeWidth?: number;
  maxWidth: number;
};
const DonutGraph: FunctionComponent<Props> = ({
  alt,
  className,
  percent,
  prefix,
  radius,
  startTheta,
  strokeWidth,
  maxWidth,
}) => {
  const [pct, setPct] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  function shouldAnimate() {
    return !(isIE() || prefersReducedMotion());
  }
  function animate() {
    let t = 0;
    if (!shouldAnimate()) {
      t = 1;
    }
    const interval = window.setInterval(() => {
      if (t >= 1) {
        clearInterval(interval);
      }
      setPct((pct) => {
        t += DT;
        return EASE(t) * percent;
      });
    }, INTERVAL);
  }
  useEffect(() => {
    if (inView) {
      animate();
    }
  }, [inView]);

  const defaultStrokeWidth = 15;
  const r = radius || 40;
  const center = { x: 50, y: 50 };
  const theta = startTheta || (3 * PI) / 2;
  const deltaT = 2 * PI * pct;

  const start = {
    x: r * cos(theta) + center.x,
    y: r * sin(theta) + center.y,
  };
  const end = {
    x: r * cos(theta + deltaT) + center.x,
    y: r * sin(theta + deltaT) + center.y,
  };

  const innerRadius = r - (strokeWidth || defaultStrokeWidth) / 2;
  const outerRadius = r + (strokeWidth || defaultStrokeWidth) / 2;

  const startInner = {
    x: innerRadius * cos(theta + deltaT) + center.x,
    y: innerRadius * sin(theta + deltaT) + center.y,
  };
  const endInner = {
    x: innerRadius * cos(theta) + center.x,
    y: innerRadius * sin(theta) + center.y,
  };
  const startOuter = {
    x: outerRadius * cos(theta + deltaT) + center.x,
    y: outerRadius * sin(theta + deltaT) + center.y,
  };
  const endOuter = {
    x: outerRadius * cos(theta) + center.x,
    y: outerRadius * sin(theta) + center.y,
  };

  const largeArc = deltaT > PI ? 1 : 0;
  const sweep = deltaT > 0 ? 1 : 0;

  const positivePath = [
    `M ${startInner.x} ${startInner.y}`,
    `A ${innerRadius} ${innerRadius} 0 ${largeArc} ${sweep ? 0 : 1} ${
      endInner.x
    } ${endInner.y}`,
    `L ${endOuter.x} ${endOuter.y}`,
    `A ${outerRadius} ${outerRadius} 0 ${largeArc} ${sweep} ${startOuter.x} ${startOuter.y}`,
    `L ${startInner.x} ${startInner.y}`,
  ];

  const negativePath = [
    `M ${endInner.x} ${endInner.y}`,
    `A ${innerRadius} ${innerRadius} 0 ${largeArc ? 0 : 1} ${sweep ? 0 : 1} ${
      startInner.x
    } ${startInner.y}`,
    `L ${startOuter.x} ${startOuter.y}`,
    `A ${outerRadius} ${outerRadius} 0 ${largeArc ? 0 : 1} ${sweep} ${
      endOuter.x
    } ${endOuter.y}`,
    `L ${endInner.x} ${endInner.y}`,
  ];

  const labelId = "donutLabel-" + percent + "-" + generateRandomString();

  return (
    <div
      ref={ref}
      className={mergeClassNames(
        "DonutGraph text-red font-bold antialiased text-[68px]",
        className
      )}
    >
      {/* <ImageWrapper maxWidth={maxWidth}> */}
      <svg viewBox="0 0 100 100" aria-labelledby={labelId}>
        <title id={labelId}>{alt}</title>
        {pct > 0 && (
          <>
            <path
              className="DonutGraph__NegativeInner text-pink stroke-current fill-current"
              fill="white"
              strokeWidth={1}
              d={negativePath.join(" ")}
            />
            <path
              className="DonutGraph__Arc text-red stroke-current fill-current"
              fill="white"
              strokeWidth={1}
              strokeLinecap="square"
              d={positivePath.join(" ")}
            />
          </>
        )}
        <g className="DonutGraph__EmbeddedText">
          <circle
            cx="50"
            cy="50"
            r={innerRadius}
            className="fill-white"
            fill="#FFFFFF"
          />
          <text y="50%" transform="translate(0, 2)">
            <tspan
              x="50%"
              textAnchor="middle"
              className="DonutGraph__Text text-red fill-current"
            >
              <tspan className="DonutGraph__Prefix">{prefix}</tspan>
              {Math.floor(percent * 100)}
              <tspan className="DonutGraph__Suffix">%</tspan>
            </tspan>
          </text>
        </g>
      </svg>
      <div className="DonutGraph__Text text-red hidden">
        <span className="DonutGraph__Prefix">{prefix}</span>
        {Math.floor(percent * 100)}
        <span className="DonutGraph__Suffix">%</span>
      </div>
      {/* </ImageWrapper> */}
    </div>
  );
};

export default DonutGraph;
