import * as React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
//import am5geodata_usaTerritories2Low from "@amcharts/amcharts5-geodata/usaTerritories2Low";
import am5geodata_usaTerritories2High from "@amcharts/amcharts5-geodata/usaTerritories2High";

import {
  reduceTerritoriesToRegions,
  filterRegion,
  tweakTerritories,
} from "./regions";

import "./styles.scss";
import { calculateColor, formatPercent, heatmapData } from "./data/heatmap";
import {
  regionLabels,
  exclude,
  regions,
  buildRegionLabels,
} from "./data/regions";
import { colors } from "./data/colors";

export type UsaMapProps = {
  mobile?: boolean;
};

const UsaMap: React.FunctionComponent<UsaMapProps> = ({ mobile }) => {
  React.useEffect(() => {
    const root = am5.Root.new("map");

    const fontSize = mobile ? "8px" : "16px";

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
    ]);

    root.interfaceColors.set("primaryButton", colors["grey-5"]);
    root.interfaceColors.set("primaryButtonHover", colors["grey-3"]);
    root.interfaceColors.set("primaryButtonDown", colors["grey-2"]);
    root.interfaceColors.set("primaryButtonActive", colors["grey-4"]);
    root.interfaceColors.set("text", colors["black"]);
    root.interfaceColors.set("alternativeText", colors["white"]);

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        //projection: am5map.geoNaturalEarth1(),
        panX: "translateX",
        panY: "translateY",
        layout: root.horizontalLayout,
        pinchZoom: false,
        homeGeoPoint: { longitude: -96.5, latitude: 38 },
      })
    );

    chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

    const regionData = reduceTerritoriesToRegions(
      am5geodata_usaTerritories2High
    );

    chart.chartContainer.get("background")?.events.on("click", () => {
      chart.goHome();
      hideHeatmap();
      previousTarget = null;
    });

    //
    // regional heatmaps
    //
    const tweakedTerritories = tweakTerritories(am5geodata_usaTerritories2High);
    type Heatmap = {
      states: am5map.MapPolygonSeries;
      lines: am5map.MapLineSeries;
      lineLabels: am5map.MapPointSeries;
    };
    type HeatmapDict = {
      [key: string]: Heatmap;
    };
    const heatmaps: HeatmapDict = {};
    //const regions = splitRegions(regionData);
    regions.forEach((region) => {
      const id = region.id;
      //console.log(filterRegion(tweakedTerritories, region));

      const states = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: filterRegion(tweakedTerritories, region),
          exclude,
          valueField: "rate",
          calculateAggregates: true,
          visible: false,
          templateField: "settings",
        })
      );

      states.mapPolygons.template.setAll({
        strokeWidth: 0,
      });

      states.set("heatRules", [
        {
          target: states.mapPolygons.template,
          dataField: "value", //
          key: "fill",

          customFunction: (sprite, min, max, value) => {
            const color = calculateColor(value);
            if (color) {
              sprite.set("fill", color);
            }
          },
        },
      ]);
      states.data.setAll(heatmapData);

      const regionLabels = buildRegionLabels(region);
      const lines = chart.series.push(
        am5map.MapLineSeries.new(root, { geoJSON: regionLabels, idField: "id" })
      );
      lines.mapLines.template.setAll({
        strokeWidth: 1,
        stroke: colors["grey-5"],
      });

      const lineLabels = chart.series.push(
        am5map.MapPointSeries.new(root, { geoJSON: regionLabels })
      );
      lineLabels.bullets.push(() => {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            centerX: am5.p0,
            centerY: am5.p50,
            textAlign: "center",
            text: "{name}",
            populateText: true,
            fontFamily: "proxima-nova,Arial,Helvetica,sans-serif",
            fontSize,
          }),
        });
      });

      heatmaps[id] = {
        states,
        //labels,
        lines,
        lineLabels,
      };
    });

    const allStates = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: tweakedTerritories,
        exclude,
        valueField: "rate",
        calculateAggregates: true,
        visible: false,
        templateField: "settings",
      })
    );

    const labels = chart.series.push(
      am5map.MapPointSeries.new(root, { polygonIdField: "polygonId" })
    );
    labels.bullets.push(() => {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center",
          text: "{name}",
          populateText: true,
          visible: true,
          //fontFamily: "proxima-nova,Arial,Helvetica,sans-serif",
          fill: colors["white"],
          fontSize,
        }),
      });
    });
    //auto labels
    type LabelData = {
      polygonId: string;
      name: string;
    };
    const skipAutoLabels = buildRegionLabels()
      .features.filter((x) => x.geometry.type === "Point")
      .map((x) => x.properties?.id.replace("LABEL-", ""));
    allStates.events.on("datavalidated", (ev) => {
      //console.log(ev.target);
      const labelData: LabelData[] = [];
      const series = ev.target;
      series.mapPolygons.each((polygon) => {
        const polygonId = polygon?.dataItem?.get("id") as string;
        const found = heatmapData.find((s) => s.id === polygonId);
        //console.log(id, found);
        let name = "";
        if (found) {
          const abbr = found.label;
          const rate = formatPercent(found.rate);
          name = `${abbr}\n[bold]${rate}[/]`;
        }

        //console.log(skipAutoLabels, polygonId);
        if (!skipAutoLabels.includes(polygonId)) {
          labelData.push({
            polygonId,
            name,
          });
        }
      });
      labels.data.setAll(labelData);
    });

    // const title = chart.children.push(
    //   am5.Label.new(root, {
    //     text: "Uptake among persons aged ≥13 years*",
    //     fontSize: 25,
    //     fontWeight: "bold",
    //     textAlign: "left",
    //     x: am5.p0,
    //     centerX: am5.p0,
    //     y: am5.p100,
    //     centerY: am5.p100,
    //     dy: -50,
    //     paddingTop: 0,
    //     paddingBottom: 0,
    //   })
    // );
    // const legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     //useDefaultMarker: true,
    //     centerX: am5.p0,
    //     x: am5.p0,
    //     y: am5.p100,
    //     centerY: am5.p100,
    //     dy: -20,
    //     background: am5.RoundedRectangle.new(root, {
    //       fill: colors["grey-2"],
    //       fillOpacity: 0.2,
    //     }),
    //     nameField: "label",
    //     fillField: "color",
    //     //clickTarget: "none",
    //   })
    // );
    // legend.valueLabels.template.set("forceHidden", true);
    // legend.markerRectangles.template.setAll({
    //   cornerRadiusTL: 0,
    //   cornerRadiusTR: 0,
    //   cornerRadiusBL: 0,
    //   cornerRadiusBR: 0,
    // });
    // legend.data.setAll([
    //   {
    //     label: "4% - 16%",
    //     color: colors["red-4"],
    //   },
    //   {
    //     label: "17% - 20%",
    //     color: colors["red-3"],
    //   },
    //   {
    //     label: "21% - 25%",
    //     color: colors["red-2"],
    //   },
    //   {
    //     label: "26% and above",
    //     color: colors["red"],
    //   },
    // ]);

    /////////////////////////////////////////
    //
    //regions
    //

    const usaRegions = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: regionData,
        exclude,
        calculateAggregates: false,
      })
    );

    usaRegions.mapPolygons.template.setAll({
      interactive: true,
      strokeWidth: 0,
      templateField: "polygonSettings",
    });

    let i = 0;
    usaRegions.mapPolygons.template.setup = (e) => {
      const key = `grey-${i + 1}`;
      if (key && colors[key]) {
        const color = colors[key] as am5.Color;
        e.set("fill", color);
        e.set("stroke", color);
      }
      i = (i + 1) % 3;
    };

    usaRegions.mapPolygons.template.states.create("hover", {
      fill: colors["grey-5"],
      stroke: colors["grey-5"],
    });

    const usaRegionLabels = chart.series.push(
      am5map.MapPointSeries.new(root, {
        geoJSON: regionLabels,
        polygonIdField: "polygonId",
      })
    );

    //e.g. West
    usaRegionLabels.bullets.push(() => {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center",
          text: "{name}",
          populateText: true,
          fontWeight: "bold",
          shadowColor: colors["white"],
          shadowBlur: 10,
        }),
      });
    });

    const homeButton = chart.children.push(
      am5.Button.new(root, {
        paddingTop: 10,
        paddingBottom: 10,
        x: am5.percent(100),
        centerX: am5.percent(100),
        opacity: 0,
        interactiveChildren: false,
        icon: am5.Graphics.new(root, {
          svgPath: "M 18,2 L 2,16 L 18,30",
          stroke: colors["red"],
        }),
        label: am5.Label.new(root, {
          text: "Back to entire map",
          fill: colors["red"],
        }),
      })
    );
    homeButton.get("background")?.setAll({ fill: colors["white"] });
    homeButton.events.on("click", () => {
      chart.goHome();
      hideHeatmap();
    });

    const showHeatmap = (heatmapId: string) => {
      //console.log("showHeatmap");
      usaRegions.hide();
      usaRegionLabels.hide();
      const heatmap = heatmaps[heatmapId];

      if (heatmap) {
        heatmap.states.show();
        //heatmap.labels.show();
        heatmap.lines.show();
        heatmap.lineLabels.show();
      }
      labels.show();
      homeButton.show();
    };
    const hideHeatmap = () => {
      //console.log("hideHeatmap");
      usaRegions.show();
      usaRegionLabels.show();

      //heatmapLabels.hide();
      Object.keys(heatmaps).forEach((id) => {
        const heatmap = heatmaps[id];
        if (heatmap) {
          heatmap.states.hide();
          //heatmap.labels.hide();
          heatmap.lines.hide();
          heatmap.lineLabels.hide();
        }
      });
      labels.hide();
      homeButton.hide();
    };
    //Click navigation
    let previousTarget: am5map.MapPolygon | null = null;
    usaRegions.mapPolygons.template.events.on("click", (ev) => {
      const { target } = ev;
      if (target) {
        if (target === previousTarget) {
          target.set("active", false);
          chart.goHome();
          hideHeatmap();
        } else {
          target.set("active", true);
          if (target.dataItem) {
            usaRegions.zoomToDataItem(
              target.dataItem as am5.DataItem<am5map.IMapPolygonSeriesDataItem>
            );

            showHeatmap(target.dataItem.get("id"));
          }
        }
        previousTarget = target;
      }
    });

    //Region labels
    usaRegions.events.on("datavalidated", (ev) => {
      chart.goHome();
      chart.zoomOut();

      let series = ev.target;
      const labelData: { polygonId: string; name: string }[] = [];
      series.mapPolygons.each((polygon) => {
        const id = polygon?.dataItem?.get("id") as string;
        const found = regionData.features.find((s) => s.id === id);

        labelData.push({
          polygonId: id,
          name: found?.properties?.name,
        });
      });

      usaRegionLabels.data.setAll(labelData);

      //Handle fragment routing
      const match = window.location.hash.match(/(US-.*)/);
      if (match) {
        const region = match[0];

        const dataItem = usaRegions.dataItems.find(
          (x) => x.get("id") === region
        );
        if (dataItem) {
          usaRegions.zoomToDataItem(dataItem);
        }
        showHeatmap(region);
      }
    });

    chart.appear(1000, 100);
    hideHeatmap();
  }, []);

  return (
    <>
      <p className="text-center text-header-2-alt mb-12">
        Click on the regions below to view CDC data by state for 2022
      </p>

      <div id={"map"} className="usa-map"></div>
      <div className="caption">
        <h2>
          PrEP uptake among persons aged{" "}
          <span style={{ fontWeight: "normal" }}>
            ≥<span className="mini-space">&nbsp;</span>
          </span>
          16 years*
        </h2>
        <div className="legend">
          <div className="item item--red-4">
            <div className="square"></div>
            <span>5% - 24%</span>
          </div>
          <div className="item item--red-3">
            <div className="square"></div>
            <span>25% - 29%</span>
          </div>
          <div className="item item--red-2">
            <div className="square"></div>
            <span>30% - 35%</span>
          </div>
          <div className="item item--red">
            <div className="square"></div>
            <span>36% and above</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsaMap;
