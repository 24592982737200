import { Feature, GeoJsonProperties, Geometry, MultiPolygon } from "geojson";
import { Region, regions } from "./data/regions";

function translateMultiPolygon(
  territories: GeoJSON.FeatureCollection,
  id: string,
  offsetLat: number,
  offsetLng: number
): GeoJSON.FeatureCollection {
  const features = territories.features.filter((f) => f.id !== id);
  let feature = territories.features.find((f) => f.id === id);

  if (feature && feature.geometry.type === "MultiPolygon") {
    const clone = JSON.parse(JSON.stringify(feature)) as Feature<
      MultiPolygon,
      GeoJsonProperties
    >;

    clone.geometry.coordinates = clone.geometry.coordinates.map((one) =>
      one.map((two) =>
        two.map((three) => [three[0] + offsetLng, three[1] + offsetLat])
      )
    );
    feature = clone;
  }
  if (feature) {
    features.push(feature);
  }
  return {
    type: territories.type,
    features,
  };
}

function scaleMultiPolygon(
  territories: GeoJSON.FeatureCollection,
  id: string,
  scaleFactor: number
): GeoJSON.FeatureCollection {
  const features = territories.features.filter((f) => f.id !== id);
  let feature = territories.features.find((f) => f.id === id);

  if (feature && feature.geometry.type === "MultiPolygon") {
    const clone = JSON.parse(JSON.stringify(feature)) as Feature<
      MultiPolygon,
      GeoJsonProperties
    >;
    let reference: number[] | null = null;
    clone.geometry.coordinates = clone.geometry.coordinates.map((one) =>
      one.map((two) =>
        two.map((three) => {
          //setup reference
          if (reference === null) {
            reference = three;
          }
          //translate to reference
          const translated = [three[0] - reference[0], three[1] - reference[1]];
          //scale
          const scaled = [
            translated[0] * scaleFactor,
            translated[1] * scaleFactor,
          ];
          //invert translation
          return [scaled[0] + reference[0], scaled[1] + reference[1]];
        })
      )
    );
    feature = clone;
  }
  if (feature) {
    features.push(feature);
  }
  return {
    type: territories.type,
    features,
  };
}

export function tweakTerritories(territories: GeoJSON.FeatureCollection) {
  const s = stream(territories)
    .pipe((data) => scaleMultiPolygon(data, "US-PR", 0.7))
    .pipe((data) => translateMultiPolygon(data, "US-PR", 7, 16))
    .pipe((data) => scaleMultiPolygon(data, "US-AK", 0.5))
    .pipe((data) => translateMultiPolygon(data, "US-AK", 8, 0))
    .pipe((data) => translateMultiPolygon(data, "US-HI", 5, -5));
  return s.data;
}
export function reduceTerritoriesToRegions(
  territories: GeoJSON.FeatureCollection
): GeoJSON.FeatureCollection {
  const territoriesModified = tweakTerritories(territories);

  const merged: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: regions.map((r) => {
      const children = territoriesModified.features.filter(
        (f) => typeof f.id === "string" && r.children.includes(f.id)
      );
      const coordinates = children.flatMap((s) => {
        if (s.geometry.type === "MultiPolygon") {
          return s.geometry.coordinates;
        } else if (s.geometry.type === "Polygon") {
          return [s.geometry.coordinates];
        }
      });
      //console.log(r.name, children);
      return {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates,
        } as GeoJSON.Geometry,
        properties: {
          name: "", //r.name,
          id: `US-${r.name}`,
          CNTRY: "United States of America",
          TYPE: "Region",
        },
        id: `US-${r.name}`,
      };
    }),
  };

  return merged;
}

type FeatureCollectionDictionary = {
  [key: string]: GeoJSON.FeatureCollection;
};

export function filterRegion(
  territories: GeoJSON.FeatureCollection,
  region: Region
): GeoJSON.FeatureCollection {
  const features = territories.features.filter((feature) => {
    const id = feature.id;
    return region.children.includes(id);
  });
  return {
    type: "FeatureCollection",
    features,
  };
}

export function filterById<T>(data: T[], region: Region): T[] {
  return data.filter((t) => {
    region.children.includes(t.id as string);
  });
}

type Stream<T> = {
  data: T;
  pipe: (transform: (before: T) => T) => Stream<T>;
};
function stream<T>(data: T) {
  function pipe(transform: (before: T) => T): Stream<T> {
    data = transform(data);
    return { data, pipe };
  }
  return { data, pipe };
}
