import * as am5 from "@amcharts/amcharts5";
import { colors } from "./colors";
import { formatPercent, valueByState } from "./heatmap";

export type Region = {
  id: string;
  name: string;
  children: string[];
};
export const regions: Region[] = [
  {
    id: "US-Northeast",
    name: "Northeast",
    children: [
      "US-CT",
      "US-ME",
      "US-MA",
      "US-NH",
      "US-NJ",
      "US-NY",
      "US-PA",
      "US-RI",
      "US-VT",
    ],
  },
  {
    id: "US-Midwest",
    name: "Midwest",
    children: [
      "US-IL",
      "US-IN",
      "US-IA",
      "US-KS",
      "US-MI",
      "US-MN",
      "US-MO",
      "US-NE",
      "US-ND",
      "US-OH",
      "US-SD",
      "US-WI",
    ],
  },
  {
    id: "US-South",
    name: "South",
    children: [
      "US-AL",
      "US-AR",
      "US-DE",
      "US-FL",
      "US-GA",
      "US-KY",
      "US-LA",
      "US-MD",
      "US-MS",
      "US-NC",
      "US-OK",
      "US-SC",
      "US-TN",
      "US-TX",
      "US-VA",
      "US-DC",
      "US-WV",
      "US-PR",
    ],
  },
  {
    id: "US-West",
    name: "West",
    children: [
      "US-AK",
      "US-AZ",
      "US-CA",
      "US-HI",
      "US-ID",
      "US-MT",
      "US-NV",
      "US-NM",
      "US-OR",
      "US-UT",
      "US-WA",
      "US-WY",
      "US-CO",
    ],
  },
];

//Custom positions for region labels
export const regionLabels: GeoJSON.FeatureCollection = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: { id: "Label-US-West", name: "West" },
      geometry: {
        type: "Point",
        coordinates: [-113.9023, 40.6143],
      },
    },
    {
      type: "Feature",
      properties: { id: "Label-US-South", name: "South" },
      geometry: {
        type: "Point",
        coordinates: [-88.6482, 34.2482],
      },
    },
    {
      type: "Feature",
      properties: { id: "Label-US-Midwest", name: "Midwest" },
      geometry: {
        type: "Point",
        coordinates: [-94.386, 42.5024],
      },
    },
    {
      type: "Feature",
      properties: { id: "Label-US-Northeast", name: "Northeast" },
      geometry: {
        type: "Point",
        coordinates: [-76.1533, 42.0219],
      },
    },
  ],
};

//attempt to fix bounds by adding a polygon around USA - obsolete
export const frame: GeoJSON.FeatureCollection = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-128.9658, 51.0973],
            [-128.9658, 22.7113],
            [-65.4447, 22.7113],
            [-65.4447, 51.0973],
            [-128.9658, 51.0973],
          ],
        ],
      },
      properties: {
        id: "US-Bounds",
      },
    },
  ],
};

export const exclude = ["US-VI", "US-GU", "US-MP", "US-AS", "FM", "MH", "PW"];

type StateCenters = {
  id: string;
  name: string;
  value: number;
  center: GeoJSON.Position;
  offset?: GeoJSON.Position;
};
export const stateCenters: StateCenters[] = [
  {
    id: "US-DE",
    name: "DE",
    value: valueByState("US-DE"),
    center: [-75.4753, 39.0237],
    offset: [-75.0336, 38.6351],
  },
  {
    id: "US-MD",
    name: "MD",
    value: valueByState("US-MD"),
    //center: [-75.7125, 38.3077],
    center: [-76.9072, 39.2366],
    offset: [-75.3441, 37.8737],
  },
  {
    id: "US-DC",
    name: "DC",
    value: valueByState("US-DC"),
    center: [-77.0171, 38.9149],
    offset: [-75.6546, 37.1123],
  },
  {
    id: "US-PR",
    name: "PR",
    value: valueByState("US-PR"),
    center: [-77.5, 26.3], //not shown at actual coords
  },
  {
    id: "US-KY",
    name: "KY",
    value: valueByState("US-KY"),
    center: [-86.5, 37.7],
  },
  {
    id: "US-WV",
    name: "WV",
    value: valueByState("US-WV"),
    center: [-82, 38.6],
  },
  {
    id: "US-VA",
    name: "VA",
    value: valueByState("US-VA"),
    center: [-79.5, 37.5],
  },
  {
    id: "US-RI",
    name: "RI",
    value: valueByState("US-RI"),
    center: [-71.472, 41.7272],
    offset: [-70.0662, 41.2675],
  },
  {
    id: "US-MA",
    name: "MA",
    value: valueByState("US-MA"),
    center: [-72, 42.3],
    offset: [-68.8418, 42.142],
  },
  {
    id: "US-CT",
    name: "CT",
    value: valueByState("US-CT"),
    center: [-72.7, 41.6],
    offset: [-71.2906, 40.393],
  },
  {
    id: "US-NJ",
    name: "NJ",
    value: valueByState("US-NJ"),
    center: [-74.5, 40.0],
    offset: [-72.515, 39.5185],
  },
  {
    id: "US-NH",
    name: "NH",
    value: valueByState("US-NH"),
    center: [-71.5, 43.5],
    offset: [-72.3, 45.9],
  },
  {
    id: "US-VT",
    name: "VT",
    value: valueByState("US-VT"),
    center: [-72.7, 44.0],
    offset: [-74, 45.7],
  },
  {
    id: "US-HI",
    name: "HI",
    value: valueByState("US-HI"),
    //center: [-157.7438, 19.8353],
    center: [-110.0966, 27.3705], //not shown at actual coords
  },
  {
    id: "US-ND",
    name: "ND",
    value: valueByState("US-ND"),
    center: [-102, 47],
  },
  {
    id: "US-MI",
    name: "MI",
    value: valueByState("US-MI"),
    center: [-86, 44],
  },
  {
    id: "US-IN",
    name: "IN",
    value: valueByState("US-IN"),
    center: [-87.5, 40],
  },
  {
    id: "US-ID",
    name: "ID",
    value: valueByState("US-ID"),
    center: [-116, 44],
  },
  {
    id: "US-MT",
    name: "MT",
    value: valueByState("US-MT"),
    center: [-110, 47],
  },
  {
    id: "US-WY",
    name: "WY",
    value: valueByState("US-WY"),
    center: [-109, 43],
  },
  {
    id: "US-AK",
    name: "AK",
    value: valueByState("US-AK"),
    center: [-118, 28], //not shown at actual coords
  },
];

export const buildRegionLabels = (
  region?: Region
): GeoJSON.FeatureCollection => {
  const features: GeoJSON.Feature<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >[] = [];
  if (!region) {
    region = {
      id: "US",
      name: "United States",
      children: regions.flatMap((r) => r.children),
    };
  }
  region.children.forEach((id) => {
    const found = stateCenters.find((state) => state.id === id);
    if (found) {
      let point = found.center;
      let name = `${found.name}\n[BOLD]${formatPercent(found.value)}[/]`;
      if (found.offset) {
        //line
        features.push({
          type: "Feature",
          properties: { id: "ARROW-" + id, name: found.name },
          geometry: {
            type: "LineString",
            coordinates: [found.center, found.offset],
          },
        });
        //move point to offset
        point = found.offset;
        name = `${found.name} [BOLD]${formatPercent(found.value)}[/]`;
      }
      features.push({
        type: "Feature",
        properties: { id: "LABEL-" + id, name },
        geometry: {
          type: "Point",
          coordinates: point,
        },
      });
    }
  });

  return {
    type: "FeatureCollection",
    features,
  };
};
